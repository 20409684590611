import api from '../../api/projects'
import { toast } from 'react-toastify'
import {
  ADD_DIVISION,
  ADD_USERADMIN_USERS,
  DELETE_DIVISION,
  DELETE_DIVISION_USER,
  DELETE_USERADMIN_USERS,
  GET_DIVISION,
  GET_DIVISION_USER_ALL,
  GET_SINGLE_DIVISION,
  GET_SINGLE_USERADMIN_USERS,
  GET_USERADMIN_USERS,
  UPDATE_DIVISION,
  UPDATE_USERADMIN_IS_DISABLED,
  UPDATE_DIVISION_IS_DISABLED,
  RESET_MFA,
  GET_BILLING_ACCOUNT,
  ADD_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  GET_SINGLE_BILLING_ACCOUNT,
  UPDATE_SINGLE_BILLING_ACCOUNT,
  GET_BILLING_ACCOUNT_DIVISION,
  ADD_BILLING_ACCOUNT_DIVISION,
  DELETE_BILLING_ACCOUNT_DIVISION,
  GET_USERADMIN_USERS_LOADING,
  DELETE_USERADMIN_USERS_REMOVE_FILES,
  DELETE_USERADMIN_USERS_TRANSFER_FILES,
  UPDATE_SINGLE_USERADMIN_USERS,
  // DIVISION_CLERE,
  GET_USER_ORGID,
  GET_USER_SELECTED_TAB,
  SET_USER_SELECTED_DIVISION,
  SET_USER_SELECTED_BILLING,
  // SET_SELECTED_BILLING_EMPTY,
  GET_BILL_AMOUNT,
  RESET_BILLING_DATA,
  GET_BILL_DETAIL,
  GET_DIVISIONS_LOADING,
  GET_BILLING_LOADING,
  GET_BILLING_ACCOUNT_LOADING
} from '../types'

export const getDivision = (params) => async (dispatch) => {

  dispatch({ type: GET_DIVISIONS_LOADING })
  const response = await api.get(`/companyadmin/division`, {
    params: params,
  })
  if(response.status === 200){
    dispatch({ type: GET_DIVISION, payload: response.data })
  }
}

export const adduserAdminDivision = (data) => async (dispatch) => {
  dispatch({ type: GET_DIVISIONS_LOADING })
  const response = await api.post('/companyadmin/division', data)
  if(response.status === 200){
    dispatch({ type: ADD_DIVISION, payload: data })
    toast.success("Division added successfully");
    // let params= {
    //   orgId:data.organisation_id
    // }
    // dispatch(getAllDivisionUsers(divdata))
  }
  else{
    toast.error("somthing error")
  }
}

export const deleteDivision = (id,divdata) => async (dispatch) => {
  const data = {
    _id: id,
  }

dispatch({ type: GET_DIVISIONS_LOADING })
  const response = await api.delete('/companyadmin/division', { data: data })
  dispatch({ type: DELETE_DIVISION })
  if(response.status === 200){
    toast.success("Division deleted successfully");
    dispatch(setActiveDivision(""));
    // const params = {
    //   orgId: divdata.orgId,
    //   divisionId: id,
    // }
    // const response = await api.get('/orgsdiv', { params })
    // console.log("response",response)
    // let userData = response.filter((item) => item.roles == "member" || item.roles == "view only");
    
    // if(id==divdata.divisionId){
    //   // dispatch(getAllDivisionUsers(divdata))
    //   dispatch({type:DIVISION_CLERE , payload: ""})
    // dispatch(getDivision())

    // }
    // else{
    //   dispatch(getAllDivisionUsers(divdata))
    // dispatch(getDivision())
    // }
  }
  else{
    toast.error("somthing error")
  }
}

export const getSingleDivision = () => async (dispatch) => {
  const response = await api.get(`/companyadmin/division/`)
  dispatch({ type: GET_SINGLE_DIVISION, payload: response.data })
}

export const updateDivision = (user) => async (dispatch) => {
  dispatch({ type: GET_DIVISIONS_LOADING })
  const response = await api.put('/companyadmin/division', user)

  if(response.status === 200){
    dispatch({ type: UPDATE_DIVISION, payload: user })
    toast.success("Division updated successfully")
    // dispatch(getAllDivisionUsers(divdata))
    // dispatch(getDivision())
  }
  else{
    toast.error("somthing error")
  }
}

// admin users actions

export const getuserAdminUsers = (params) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  const response = await api.get('/user', {
    params: params,
  })
  if (response.status === 200) {
    setTimeout(() => { dispatch({ type: GET_USERADMIN_USERS, payload: response.data }) }, 500)
  }
}

export const getSelecteduserDetails = (params) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  dispatch({ type: GET_USER_ORGID, payload: params })
}

export const setActiveTab = (params) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  dispatch({ type: GET_USER_SELECTED_TAB, payload: params }) 
}

export const setActiveDivision = (params) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  dispatch({ type: SET_USER_SELECTED_DIVISION, payload: params }) 
}

// 

export const getSingleUserAdminuser = (id) => async (dispatch) => {
  const response = await api.get(`/user/${id}`)
  dispatch({ type: GET_SINGLE_USERADMIN_USERS, payload: response.data })
  // console.log("singleuser-data",response.data)
  
}

export const MFAResetStatus = (id, data) => async (dispatch) => {
  const patchData = {
    _id: id,
    resetMFA: data,
  }
  const response = await api.patch(
    '/user/resetMFAStatus',
    patchData,
  )
  dispatch({ type: RESET_MFA, payload: data })

  if(response.status === 200){
    toast.success("MFA reset successfully");
  }
  else{
    toast.error("somthing error")
  }
}

export const adduserAdminUsers = (data) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  const response = await api.post('/user', data)
  if (response.status === 200) {
    dispatch({ type: ADD_USERADMIN_USERS, payload: data })
    toast.success("User added successfully");
  }
  else {
    toast.error("somthing error")
  }
}

export const updateSingleUserAdminUsers = (data) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  const response = await api.put('/user', data)

  if (response.status === 200) {
    dispatch({ type: UPDATE_SINGLE_USERADMIN_USERS, payload: data })
    toast.success("User updated successfully");
  }
  else {
    toast.error("somthing error")
  }
}

export const deleteuserAdminUsers = (id , formvalues) => async (dispatch) => {
  const data = {
    _id: id,
  }
  const response = await api.delete('/user', { data: data })
  dispatch({ type: DELETE_USERADMIN_USERS })
  if(response.status === 200){
    toast.success("User deleted successfully");
    dispatch(getuserAdminUsers(formvalues))
  }
  else{
    toast.error("somthing error")
  }
}
export const deleteuserAdminUsersAndRmoveFiles = (id,formvalues) => async (dispatch) => {
  const data = {
    _id: id,
  }
  const response = await api.delete('/user/deleteRemoveAllFiles', { data: data })

  dispatch({ type: DELETE_USERADMIN_USERS_REMOVE_FILES })
  if (response.status === 200) {
    toast.success("User deleted and Files removed successfully");
    dispatch(getuserAdminUsers(formvalues))

  }
  else {
    toast.error("somthing error")
  }
}
export const deleteuserAdminUsersAndTransferFiles = (id,formvalues) => async (dispatch) => {
  const data = id
  const response = await api.delete('/user/deleteTransferAllFiles', { data: data })
  dispatch({ type: DELETE_USERADMIN_USERS_TRANSFER_FILES })
  if (response.status === 200) {
    if (response.data.message === "User deleted, but does not have bucket to transfer") {
      toast.success("User deleted, but does not have bucket to transfer");
    }
    if (response.data.message === "User deleted and transfered files") {
      toast.success("User deleted and Files transferred successfully");
    }
    if (response.data.message === "Transfer user does not have bucket") {
      toast.error("Transfer user does not have a bucket ");
    }
    dispatch(getuserAdminUsers(formvalues))

  }
  else {
    toast.error("somthing error")
  }
}

export const fetchBucketList = (data) => async (dispatch) => {
  const response = await api.post('/userbucket/Billings',data)
  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Something went wrong");
    return null;
  }
}

// 
export const updateUserAdminIsDisabled = (id, data) => async (dispatch) => {
  const patchData = {
    _id: id,
    is_disabled: data,
  }
  const response = await api.patch(
    '/user/disabled',
    patchData,
  )
  dispatch({ type: UPDATE_USERADMIN_IS_DISABLED, payload: data })
  if(response.status === 200){
    toast.success("User access updated successfully"
    )
  }
  else{
    toast.error("somthing error")
  }
}
// Division

export const getAllDivisionUsers = (params) => async (dispatch) => {
  const valuedata = {
    orgId: params.orgId,
    divisionId: params.divisionId,
  }
  const onchangeId = valuedata.divisionId

  const response = await api.get('/orgsdiv', { params })
  if (response.status === 200) {
    dispatch({ type: GET_DIVISION_USER_ALL, payload: response.data, onchangeId })
  }
  else {
    toast.error("somthing error")
  }

}

export const deleteDivisionUser = (id,divdata) => async (dispatch) => {
  const data = {
    _id: id,
  }

  const response = await api.delete('/user', { data: data })
  dispatch({ type: DELETE_DIVISION_USER })
  if(response.status === 200){
    toast.success("User deleted successfully");
    dispatch(getAllDivisionUsers(divdata))
    // dispatch(getuserAdminUsers(divdata))
  }
  else{
    toast.error("somthing error")
  }
}

export const updateDivisionIsDisabled = (id, data,divdata) => async (dispatch) => {
  const patchData = {
    _id: id,
    is_disabled: data,
  }
  const response = await api.patch(
    '/user/disabled',
    patchData,
  )
  dispatch({ type: UPDATE_DIVISION_IS_DISABLED, payload: data })
  if(response.status === 200){
    toast.success("User access updated successfully");
    dispatch(getAllDivisionUsers(divdata))
    dispatch(getuserAdminUsers(divdata))

  }
  else{
    toast.error("somthing error")
  }
}

// Billing Account

export const getBillingAccount = (params) => async (dispatch) => {

  dispatch({ type: GET_BILLING_ACCOUNT_LOADING })
  const response = await api.get('/billing', {
    params: params,
  })
 
  if(response.status ===  200){
    dispatch({ type:  GET_BILLING_ACCOUNT, payload: response.data })
  }

}

export const addBilling = (data,params) => async (dispatch) => {
  const response = await api.post('/billing', data)

  dispatch({ type: ADD_BILLING_ACCOUNT, payload: data })
  if(response.status === 200){
    toast.success("Billing added successfully");
    // dispatch(getBillingAccount(params))
  }
  else{
    toast.error("somthing error")
  }
}

export const setActiveBillingId = (params) => async (dispatch) => {
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  dispatch({ type: SET_USER_SELECTED_BILLING, payload: params }) 
}


export const getSingleBillingAccount = (data) => async (dispatch) => {
  const response = await api.get(`billing/account?id=${data}`)

  dispatch({ type: GET_SINGLE_BILLING_ACCOUNT, payload:response.data })
}

export const updateSingleBillingAccount = (data,Activeid) => async (dispatch) => {
  const response = await api.put('/billing', data)
  dispatch({ type: UPDATE_SINGLE_BILLING_ACCOUNT, payload: data })

  if(response.status === 200){
    toast.success("Billing updated successfully");
  }
  else{
    toast.error("somthing error")
  }

}
export const deleteBillingAccount = (id,formvalues) => async (dispatch) => {
  const data = {
    _id: id,
  }
  const response = await api.delete('/billing', { data: data })
  dispatch({ type: DELETE_BILLING_ACCOUNT })
  if(response.status === 200){
    toast.success("Billing deleted successfully.");
    dispatch(getBillingAccount(formvalues))
  }
  else{
    toast.error("Billing not deleted.")
  }
}

export const getBillingDivisionUser = (data) => async (dispatch) => {
  let datasent={billingAccountId:[...data]}
  const response = await api.post('/billing/division',datasent)
  dispatch({ type: GET_USERADMIN_USERS_LOADING })
  if(response.status ===  200){
      dispatch({ type: GET_BILLING_ACCOUNT_DIVISION, payload:response.data , data})
      // dispatch({ type: GET_BILLING_ACCOUNT_DIVISION, payload: data})
     
  }
}

export const addBillingAccountDivision = (data,formval) => async (dispatch) => {
  const response = await api.post('/billing/addDivision', data)

  dispatch({ type: ADD_BILLING_ACCOUNT_DIVISION, payload: data })
  if(response.status === 200){
    toast.success("Division added successfully");
    dispatch(getBillingAccount(formval))
  }
  else{
    toast.error("somthing error")
  }
}
export const deleteBillingAccountDivision = (id, selectedBill, actionbillingID) => async (dispatch) => {
  const data = {
    billingAccountId: id.billingAccountId,
    divisionId: id.division_id
  }
  const response = await api.delete('/billing/deleteDivision', { data: data })

  dispatch({ type: DELETE_BILLING_ACCOUNT_DIVISION })
  if (response.status === 200) {
    toast.success("Billing division deleted successfully");
    // await api.put('/billing', selectedBill)
    // dispatch({ type: UPDATE_SINGLE_BILLING_ACCOUNT, payload: selectedBill})
    dispatch(getBillingDivisionUser([id.billingAccountId]))
  }
  else {
    toast.error("somthing error")
  }
}

//Billing query


export const getBillbigQuery = (params) => async (dispatch) => {
  dispatch({ type: GET_BILLING_LOADING })
  const response = await api.get(`/bigquery/cost`, { params })
  if (response.status === 200) {
    dispatch({ type: GET_BILL_AMOUNT, payload: response.data })
  }
  else {
    toast.error("Query has failed")
  } 
    
}

export const resetBillinigData = (params) => async (dispatch) => {
    dispatch({ type: RESET_BILLING_DATA, payload: [] })  
}


export const getBillbigQuerydetail = (params) => async (dispatch) => {
  dispatch({ type: GET_BILLING_LOADING })
  const response = await api.get(`/bigquery`,{ params }) 
  
  if (response.status === 200) {
    dispatch({ type: GET_BILL_DETAIL, payload: response.data })
  }
  else {
    toast.error("Query has failed")
  } 
  
}


// export const getBillingDivisionUser = (data) => {
//   console.log("api_data",data)
//   return function (dispatch){
//     axios.get('localhost:5000/billing/division', {data}
     
//     ).then((resp)=>{
//       console.log ("resp select",resp);

//     })
    //  axios
    //    .get('',data)
    //    .then((resp) => {
    //     // dispatch({ type: GET_SINGLE_BILLING_ACCOUNT, payload:resp.data })
    //     // console.log ("resp select_data",resp.data);

    //  })
    //    .catch((error) => console.log("error",error) ) ;
//      }
//  }