import api from '../api/projects'
import Cookies from 'js-cookie'

const TokenKey = 'sg-analysis';

class AuthService {
  static async verify() {
    const url = `/currentUser`;
    try {
      const data  = await api.get(url);
      return data.data;
    } catch (e) {
      return false;
    }
  }

  static isLoggedIn() {
    return localStorage.getItem(TokenKey) !== null
  }

  static getToken() {
    return localStorage.getItem(TokenKey)
  }

  static setToken(token) {
    Cookies.set(TokenKey, token, { expires: 3 })
    // return localStorage.setItem(TokenKey, token);
  }

  static logout() {
    localStorage.removeItem(TokenKey);
  }
}

export default AuthService;