import React from 'react'
import Modal from 'react-bootstrap/Modal'

function AddSubBilling() {

  return (
    <div className="mod">
      <Modal.Body className="">
        <div className="pull"> </div>
        <h4 className="text-center">Update Billing Account</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label for="division_id">Division Name</label>
              <select
                className='' name="division_id" >
                <option> </option>
              </select>
            </div>
            <div class="col">
              <div className="col add-user-select">
                <label for="organisation_id">Organisation ID</label>
                <select
                  className='' name="organisation_id" >
                  <option> </option>
                </select>
              </div>
            </div>
          </div>
          <div className="text-center">
            {/* <button className='lg'>ADD</button> */}
            <button type="submit" className="add-btn btn btn-primary  m-3">
              Update billing Account
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* <ToastContainer/> */}
    </div>
  )
}
export default AddSubBilling
