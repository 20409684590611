import React, { useState, useEffect } from "react";
import Visual from "./visualization/visual";
import AutoGenReport from "./autoGenReport";
import Parameters from "./parameters";
import Reports from "./reports";
import { analysisAddReport, getReports } from "../../../actions/projects";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import IGVBrowser from "./igv/igv";

const ResultsModal = (props) => {
  const dispatch = useDispatch();
  console.log("props", props);
  const [tab, setTab] = useState(1);

  const [commentsTab, setCommentsTab] = useState(null);
  const [paramsTab, setParamsTab] = useState("inputs");
  const [allReportGraphs, setAllReportGraphs] = useState([]);
  const [visOff, setVisOff] = useState(true);
  // const [rrrport, setRrrport] = useState([{
  //   userName: "User AAA",
  //   time: Date.now(),
  //   graph: "https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1)-p-500.png",
  //   details: "Enter report details here",

  // }]);

  // useEffect(() => {
  //   if(props.pipeline_info.pipelineDetails?.wdlFileName === "rnaseq_full_workflow.wdl"){
  //     setVisOff(false)
  //   }
  // dispatch(getReports(props.pipeline_info.run_id))

  // }, [props.pipeline_info.report]);

  return (
    <>
      <div className="div-block-13">
        <div className="text-block-2">{props.run_id}</div>
        <div className="text-block">Status: Success</div>
        {/* <div className="text-block">Completion Date</div> */}
        <div className="div-block-50">
          <div
            className="div-block-51"
            style={{
              display: "none",
            }}
          >
            Edit
          </div>
          <div
            className="div-block-51 active"
            style={{
              display: "none",
            }}
          >
            Run
          </div>
          <div
            className="div-block-25"
            onClick={() => props.setOpenResultsModal(false)}
          >
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png"
              loading="lazy"
              width={16}
              alt=""
            />
            <div className="text-block-3">Close</div>
          </div>
        </div>
      </div>
      <div className="div-block-27">
        <div
          className="div-block-39"
          onClick={() => setTab(0)}
          style={
            visOff
              ? { display: "none" }
              : tab === 0
              ? {
                  backgroundColor: "#4986ff",
                  color: "#fff",
                }
              : {}
          }
        >
          Report
        </div>
        <div
          className="div-block-39"
          onClick={() => setTab(1)}
          style={
            tab === 1
              ? {
                  backgroundColor: "#4986ff",
                  color: "#fff",
                }
              : {}
          }
        >
          Auto Generated Report
        </div>
        {/* <div
          className="div-block-39"
          onClick={() => setTab(2)}
          style={
            tab === 2
              ? {
                  backgroundColor: "#4986ff",
                  color: "#fff",
                }
              : {}
          }
        >
          Parameters
        </div> */}
        <div
          className="div-block-39"
          onClick={() => setTab(3)}
          style={
            visOff
              ? { display: "none" }
              : tab === 3
              ? {
                  backgroundColor: "#4986ff",
                  color: "#fff",
                }
              : {}
          }
        >
          Interactive Visualization
        </div>
        <div
          className="div-block-39"
          onClick={() => setTab(4)}
          style={
            visOff
              ? { display: "none" }
              : tab === 4
              ? {
                  backgroundColor: "#4986ff",
                  color: "#fff",
                }
              : {}
          }
        >
          IGV
        </div>
      </div>

      <div
        style={{
          display: tab === 0 ? "" : "none",
          overflow: "auto",
        }}
      >
        <Reports pipeline_info={props.pipeline_info} />
      </div>

      <div
        style={{
          display: tab === 1 ? "" : "none",
        }}
      >
        <AutoGenReport pipeline_info={props.pipeline_info} />
      </div>
      <div
        style={{
          display: tab === 2 ? "" : "none",
        }}
      >
        {/* <Parameters pipeline_info={props.pipeline_info} /> */}
      </div>
      <div
        style={{
          display: tab === 3 ? "" : "none",
          height: "85vh",
          alignContent: "flex-start",
          overflow: "auto",
        }}
      >
        {/* <Visual
          pipeline_info={props.pipeline_info}
        /> */}
      </div>
      <div
        style={{
          display: tab === 4 ? "" : "none",
        }}
      >
        {/* <IGVBrowser /> */}
      </div>
    </>
  );
};

export default ResultsModal;
