import Swal from 'sweetalert2'
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
})
const ShowToast = (message, type = "info") => {
  toast.fire({
    icon: type,
    title: message
  })
};

export default ShowToast;