import {
  ADD_ADMIN_USERS,
  DELETE_ADMIN_USERS,
  GET_ADMIN_USER,
  GET_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  UPDATE_IS_DISABLED,
  GET_ADMIN_USERS_LOADING,
} from '../actions/types'

const initialState = {
  adminUsers: [],
  user: {},
  loading : false,

}

export const interalAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_USERS_LOADING:
      return{
        ...state,
        loading : true,
      }
    case GET_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
        loading : false,
      }

    case DELETE_ADMIN_USERS:
      const data = state.adminUsers.filter(
        (data) => data._id !== action.payload,
      )

      return {
        ...state,
        adminUsers: data,
      }

    case ADD_ADMIN_USERS:
      return {
        ...state,
        // adminUsers: state.adminUsers.concat(action.payload)
      }

    case UPDATE_IS_DISABLED:
      return {
        ...state,
        _id: {
          ...state.adminUsers,
          ...action.payload,
        },
      }

    case GET_ADMIN_USER:
      return {
        ...state,
        adminUsers: action.payload,
      }

    case UPDATE_ADMIN_USER:
      return {
        ...state,
        user: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
