import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Administration from "./Administration/Administration.jsx";
import Divisions from "./Division/index.jsx";
import Down from "./Billing/Billing.jsx";
import BillingAccount from "./BillingAccount/index.jsx";
import { Box } from "@mui/material";

function TabPanel({ children, index }) {
  return (
    <div id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function LayoutComponent() {
  const currentUser = useSelector((state) => state.projects.currentUser);
  const [menus, setMenus] = useState([]);
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    let divisionList = [];
    if (currentUser.roles === "division admin") {
      divisionList = [
        { id: 0, name: "Divisions", routeName: "divisions", component: <Divisions /> },
        { id: 1, name: "Billing", routeName: "billing", component: <Down /> },
        { id: 2, name: "Billing Account", routeName: "billing-account", component: <BillingAccount /> }
      ];
    } else if (currentUser.roles === "member") {
      divisionList = [{ id: 0, name: "Billing", routeName: "billing", component: <Down /> }];
    } else {
      divisionList = [
        { id: 0, name: "Administration", routeName: "administration", component: <Administration /> },
        { id: 1, name: "Divisions", routeName: "divisions", component: <Divisions /> },
        { id: 2, name: "Billing", routeName: "billing", component: <Down /> },
        { id: 3, name: "Billing Account", routeName: "billing-account", component: <BillingAccount /> }
      ];
    }
    setMenus(divisionList);
  }, [currentUser.roles]);

  return (
    <div>
      <nav>
        <ul>
          {menus.map((item) => (
            <li key={item.id} style={{ background: pathname === `${url}/${item.routeName}` ? 'rgba(82, 171, 255, 0.2)' : '' }}>
              <Link to={`${url}/${item.routeName}`}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <Switch>
        {menus.map((item) => (
          <Route key={item.id} exact path={`${path}/${item.routeName}`}>
            <TabPanel index={item.id}>{item.component}</TabPanel>
          </Route>
        ))}
      </Switch>
    </div>
  );
}

export default LayoutComponent;
