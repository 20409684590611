import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Table,
  Button,
  Dropdown,
  DropdownButton,
  Modal
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import { Trash, PencilSquare } from 'react-bootstrap-icons';
import {
  deleteuserAdminUsers,
  getDivision,
  getSingleUserAdminuser,
  getuserAdminUsers,
  updateUserAdminIsDisabled,
  deleteuserAdminUsersAndTransferFiles,
  deleteuserAdminUsersAndRmoveFiles,
  MFAResetStatus,
  fetchBucketList,
} from "../../../actions/userAdmin/actionCreators";
import Loader from "../../loader/Loader.component";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import "../style.css";

function Administration() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const history = useHistory();

  const data = useSelector((state) => state.userAdmin.userAdminusers);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const isLoader = useSelector((state) => state.userAdmin.loading);

  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState({});
  const [transferUserId, setTransferUserId] = useState("");
  const [transUserError, setTransUserError] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isSwitchDisabled, setSwitchDisabled] = useState(false);

  const [bucketList, setBucketList] = useState([]);
  const [selectedBucketId, setSelectedBucketId] = useState("");
  const [userSelected, setUserSelected] = useState(false);

  const formvalues = useMemo(() => {
    return {
      orgId:
        currentUser?.roles === "company admin" ||
          currentUser?.roles === "division admin"
          ? currentUser?.org_id
          : selectedOrg,
    };
  }, [currentUser, selectedOrg]);

  const userData = useMemo(() => {
    return data
  }, [data]);

  useEffect(() => {
    if (
      currentUser.roles === "internal admin" ||
      currentUser.roles === "company admin"
    ) {
      dispatch(getDivision(formvalues));
      dispatch(getuserAdminUsers(formvalues));
    } else {
      history.push("/");
    }
  }, [currentUser.roles, dispatch, history, formvalues]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((key, id) => {
    const message =
      key === "removeUser"
        ? "Are you sure to remove user?"
        : key === "removeUserAndFile"
          ? "Are you sure to delete user and remove files?"
          : key === "removeUserTransferFile"
            ? "Are you sure to Delete user and transfer files?"
            : "";
    setAlert({ key: key, id: id, message: message });
    setShow(true);
  }, []);

  const navigateto = useCallback(() => {
    navigate.push("/admin/users/add-user");
  }, [navigate]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleDeleteUser = useCallback((id) => {

    if (id && id !== "") {
      dispatch(deleteuserAdminUsers(id, formvalues));
    }
  }, [dispatch, formvalues]);

  const handleDeleteUserAndRemoveFiles = useCallback((id) => {

    if (id && id !== "") {
      dispatch(deleteuserAdminUsersAndRmoveFiles(id, formvalues));
    }
  }, [dispatch, formvalues]);

  const handleDeleteUserAndTransferFiles = useCallback((delUserId, transUserId, bucketId) => {
    if (delUserId && delUserId !== "" && transUserId && transUserId !== "" && bucketId && bucketId !== "") {
      const id = {
        userIdToDelete: delUserId,
        userIdToTransfer: transUserId,
        billingId: bucketId,
      };
      dispatch(deleteuserAdminUsersAndTransferFiles(id, formvalues));
    }
  }, [dispatch, formvalues]);

  const handleAlert = useCallback(() => {
    if (alert.key === "removeUser") {
      handleDeleteUser(alert.id);
      setShow(false);
    }

    if (alert.key === "removeUserAndFile") {
      handleDeleteUserAndRemoveFiles(alert.id);
      setShow(false);
    }

    if (alert.key === "removeUserTransferFile") {
      if (!transferUserId) {
        setTransUserError("Select user to transfer bucket");
      } else if (!selectedBucketId) {
        setTransUserError("Select bucket");
      } else {
        handleDeleteUserAndTransferFiles(alert.id, transferUserId, selectedBucketId);
        setShow(false);
        setTransferUserId("");
        setBucketList([]);
        setSelectedBucketId('');
        setUserSelected(false);
      }
    }
  }, [alert, handleDeleteUser, handleDeleteUserAndRemoveFiles, handleDeleteUserAndTransferFiles, transferUserId, selectedBucketId]);


  const updateUser = useCallback((id) => {
    dispatch(getSingleUserAdminuser(id))
      .then(() => {
        navigate.push({
          pathname: "/admin/users/update-user",
          state: {
            _id: id,
          },
        });
      })
      .catch((error) => {
        console.error('Error updating user access:', error);
      });
  }, [dispatch, navigate]);

  const handleAccessSwitch = useCallback((id, disabled) => {
    if (!isSwitchDisabled) {
      setSwitchDisabled(true);
      dispatch(updateUserAdminIsDisabled(id, !disabled))
        .then(() => {
          dispatch(getuserAdminUsers(formvalues));
          setSwitchDisabled(false);
        })
        .catch((error) => {
          setSwitchDisabled(false);
          console.error('Error updating user access:', error);
        });
    }

  }, [dispatch, isSwitchDisabled, formvalues]);

  const resetMFA = useCallback((id, resetStatus) => {
    dispatch(MFAResetStatus(id, resetStatus));
  }, [dispatch]);

  const tranferUser = useCallback(() => {
    const handleUserChange = async (e) => {
      const userId = e.target.value;
      setTransferUserId(userId);
      setTransUserError("");
      setUserSelected(true);

      // Fetch bucket list for the selected user
      if (userId) {
        const data = {
          userIdToTransfer: userId,
          orgId: formvalues?.orgId,
        };
        const result = await dispatch(fetchBucketList(data));
        if (result) {
          setBucketList(result);
        } else {
          setBucketList([]);
        }
      } else {
        setBucketList([]);
        setUserSelected(false);
      }
    };

    const handleBucketChange = (e) => {
      setSelectedBucketId(e.target.value);
    };

    return (
      <div className="col add-user-select mt-3">
        <label htmlFor="transferUser">Choose the user to transfer files to</label>
        <select name="transferUser" onChange={handleUserChange}>
          <option value="">Choose</option>
          {userData?.length && userData.map((item) => (
            item._id !== alert.id ? (
              <option key={item._id} value={item._id}>{item.first_name + " " + item.last_name}</option>
            ) : null
          ))}
        </select>
        {userSelected && bucketList.length === 0 ? (
          <p className="text-danger mt-2">No file directory available for the selected user.</p>
        ) : (
          bucketList.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <label htmlFor="bucket">Choose a transfer billing bucket</label>
              <select name="bucket" onChange={handleBucketChange}>
                <option value="">Choose</option>
                {bucketList.map((bucket) => (
                  <option key={bucket._id} value={bucket._id}>{bucket.billing_account_name}</option>
                ))}
              </select>
            </div>
          )
        )}
        <p className="text-danger mt-2">{transUserError}</p>
      </div>
    );
  }, [userData, alert.id, bucketList, formvalues, transUserError,userSelected,dispatch]);


  const handleBack = useCallback(() => {
    navigate.push("/internaladmin");
  }, [navigate]);

  const toChangeFirstLetterCapital = (role) => {
    return role?.charAt(0).toUpperCase() + role?.slice(1);
  }

  return (
    <>
      {/* <ToastContainer autoClose={1000} /> */}
      <div className="admin-table-container">
        <div className="header">
          <div className="header-label">User</div>
          <div className="header-section">
            <button className="add-btn ia-btn" variant="primary" onClick={navigateto}>
              Add User
            </button>
            {currentUser.roles === "internal admin" ? (
              <button
                className="add-btn ia-btn"
                variant="primary"
                style={{ marginLeft: "10px" }}
                onClick={handleBack}
              >
                Back
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`table-responsive-md admin-user-table-container ${userData.length <= 3 ? "user-table" : ""
            }`}
        >
          {isLoader ? (
            <div className="loader flex-center">
              <Loader />
            </div>
          ) : (
            <>
              <Table responsive className="admin-user-table" bordered>
                <thead style={{ background: '#0a1352', color: 'white' }}>
                  <tr>
                    <th>User Name</th>
                    <th>Nick Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th>Role</th>
                    <th>MFA</th>
                    <th>Access</th>
                    <th style={{ width: "150px", textAlign: "center" }}>
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.length !== 0 &&
                    (rowsPerPage > 0
                      ? userData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : userData
                    ).map((item, i) => (
                      <tr key={i} className="userRow">
                        <td onClick={() => updateUser(item._id)}>{item?.username}</td>
                        <td onClick={() => updateUser(item._id)}>{item?.nickname}</td>
                        <td onClick={() => updateUser(item._id)}>{item?.first_name}</td>
                        <td onClick={() => updateUser(item._id)}>{item?.last_name}</td>
                        <td onClick={() => updateUser(item._id)}>{item?.email}</td>
                        {/* <td>{item.phone}</td> */}
                        <td onClick={() => updateUser(item._id)}>{toChangeFirstLetterCapital(item?.roles)}</td>
                        <td><button className='mfaresetbtn'
                          onClick={() => resetMFA(item._id, false)}>Reset</button></td>
                        <td>
                          <BootstrapSwitchButton
                            data-onstyle="outline-primary"
                            data-offstyle="outline-secondary"
                            checked={!item.is_disabled}
                            onChange={() =>
                              handleAccessSwitch(item?._id, item?.is_disabled)
                            }
                            disabled={isSwitchDisabled}
                          />
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center"
                            }}
                          >
                            <button
                              onClick={() => updateUser(item._id)}
                              className="user_edit_btn"
                            >
                              <PencilSquare style={{ marginRight: "8px" }} />Edit
                            </button>

                            <DropdownButton
                              className="customDropDown"
                              id="dropdown-basic-button"
                              title={<div className='user_delete_btn'> <Trash
                                className="trash-icon"
                                style={{ marginRight: "8px" }}
                              />Delete</div>}
                            >

                              <Dropdown.Item
                                onClick={() =>
                                  handleShow("removeUser", item._id)
                                }
                              >
                                Delete user account
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleShow("removeUserAndFile", item._id)
                                }
                              >
                                Delete & remove all files
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleShow("removeUserTransferFile", item._id)
                                }
                              >
                                Delete & transfer files to another user
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {userData?.length > 10 ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
              {userData?.length === 0 ? (
                <div className="flex-center" style={{ padding: "24px" }}>
                  There are no records to display
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      {/*  */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="sm"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.message}
          {alert.key === "removeUserTransferFile" ? tranferUser() : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleAlert()}
            disabled={alert.key === "removeUserTransferFile" && bucketList.length === 0}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Administration;
