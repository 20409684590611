import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import {
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  deleteAdminUsers,
  getAdminUsers,
  updateIsDisabled,
  MFAResetStatus,
} from '../../actions/InternalAdmin/actionCreators';
import AddUser from './AddUser';
import { getSelecteduserDetails } from '../../actions/userAdmin/actionCreators';
import UserData from './UserData';
import Loader from "../loader/Loader.component";

function InternalAdmin() {

  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedID, setselectedID] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const users = useSelector((state) => state.admin.adminUsers);
  const isLoader = useSelector((state) => state.admin.loading);
  const currentUser = useSelector((state) => state.projects.currentUser);

  useEffect(() => {

    if (currentUser.roles === "internal admin") {
      dispatch(getAdminUsers())
    } else {
      history.push("/")
    }
  }, [currentUser.roles, dispatch, history])

  const handleAccessSwitch = useCallback((id, disabled) => {
    dispatch(updateIsDisabled(id, !disabled))
      .then(() => {
        dispatch(getAdminUsers());
      })
      .catch((error) => {
        console.error('Error updating user access:', error);
      });
  }, [dispatch]);

  const resetMFA = useCallback((id, resetStatus) => {
    dispatch(MFAResetStatus(id, resetStatus));
  }, [dispatch]);

  const handleView = useCallback((id) => {
    dispatch(getSelecteduserDetails(id));
    history.push('/admin/users/administration');
  }, [dispatch, history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = useCallback((id) => {
    setOpen(true);
    setselectedID(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setselectedID("");
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    dispatch(deleteAdminUsers(selectedID));
  }, [dispatch, selectedID]);

  const paginatedUsers = useMemo(() => {
    return rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users;
  }, [users, page, rowsPerPage]);

  return (
    <div className="table admin-user-table">
      <div className="internaladminadd">
        <Button
          className=" internaladminaddcolor"
          variant="primary"
          onClick={() => setModalShow(true)}
        >
          Add
        </Button>
        <AddUser
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>

      {(isLoader) ? <Loader /> : (
        <>
          <Table bordered hover="sm">
            <thead className="admin-table-head"  style={{background:'#0a1352',color:'white'}}>
              <tr>
                <th>Organization</th>
                <th>Org. Billing Email Address</th>
                <th>Org Address</th>
                <th>Administrator First Name</th>
                <th>Administrator Last Name</th>
                <th>Administrator Email</th>
                <th>MFA</th>
                <th>Access</th>
                <th style={{ width: "150px", textAlign: "center" }}>Options</th>
              </tr>
            </thead>
            <tbody className="admin-table-body">
              {users?.length !== 0 && paginatedUsers?.map((data, id) => (
                <UserData data={data} key={data._id}
                  handleAccessswitch={handleAccessSwitch}
                  resetMFA={resetMFA}
                  handleView={handleView}
                  handleClickOpen={handleClickOpen}
                />
              ))}
            </tbody>
          </Table>
          {
            users?.length > 10 ?
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> : null
          }
        </>)}
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete the admin user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm you wish to delete this admin user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleConfirm} autoFocus>
            Confirm
          </MaterialButton>
          <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InternalAdmin