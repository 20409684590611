import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import DriveModal from "./driveModal";

const OtherSettings = ({ plf, setOtherSettingsOut, workflow }) => {
  const [otherSettings, setOtherSettings] = useState([]);
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [activeChoosenInput, setActiveChoosenInput] = useState();

  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);

  useEffect(() => {
    let _otherSettings = [];
    workflow?.steps.others?.settings.forEach((item) => {
      item.value = item.test_input;
      _otherSettings.push(item);
    });
    setOtherSettings(_otherSettings);
    setOtherSettingsOut(_otherSettings);
  }, [workflow]);

  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    try {
      let selectedRow = Object.keys(rowSelection);
      selectedRow = parseInt(selectedRow[0]);

      let x = plf[selectedRow];
      if (!x) return;
      let _otherSettings = otherSettings.map((item) => {
        if (item.name === activeChoosenInput.name) {
          return { ...item, value: x.file_path, file_doc: x };
        }
        return item;
      });
      setOtherSettings(_otherSettings);
      setOtherSettingsOut(_otherSettings);
    } catch (error) {
      console.log(error);
    }
  }, [rowSelection]);

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        {/* <div className="text-block-50">{"stepInfo"}</div> */}
        <div className="text-block-50"></div>
        <div className="div-block-95-copy">
          {otherSettings.map((otherSetting, index) => (
            <div
              className="div-block-96"
              style={{
                display: "flex",
              }}
            >
              <div
                className="text-block-52"
                style={{
                  display: "block",
                }}
              >
                {otherSetting.name.charAt(0).toUpperCase() +
                  otherSetting.name.slice(1)}{" "}
                - <br />
                <b>{otherSetting?.value}</b>
                {/* <b>{otherSetting?.value.split("/").pop()}</b> */}
              </div>
              {handleRefSelect.length > 0 ? (
                <div
                  className="reference-dropdown"
                  onClick={() => setShowDriveModal(true)}
                >
                  {handleRefSelect?.selectedRows[0]?.file_name}
                </div>
              ) : (
                ""
              )}
              <div className="div-block-98">
                {otherSetting.interface_type.includes("dropdown") && (
                  <Select
                    value={{
                      value: otherSetting.value,
                      label: otherSetting.value,
                    }}
                    onChange={(selectedOption) => {
                      const updatedOtherSettings = [...otherSettings];
                      updatedOtherSettings[index].value = selectedOption.value;
                      updatedOtherSettings[index].file_doc = null;
                      setOtherSettings(updatedOtherSettings);
                    }}
                    options={otherSetting.options}
                    className={"reference-dropdown"}
                    styles={{
                      width: "100%",
                    }}
                    isDisabled={handleRefSelect.length === 0 ? false : true}
                    placeholder="Reference Files"
                    menuPosition="fixed"
                    menuPlacement="auto"
                  />
                )}
                <div
                  // className="text-block-53"
                  className="btn-1 type-2"
                  style={{
                    marginLeft: "14px",
                    display: otherSetting.interface_type.includes(
                      "drive_file_selector"
                    )
                      ? "flex"
                      : "none",
                  }}
                  onClick={() => {
                    setShowDriveModal(true);
                    setActiveChoosenInput(otherSetting);
                  }}
                >
                  <div
                    className="btn-1-text"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Choose From <br />
                    Storage
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DriveModal
        show={showDriveModal}
        handleClose={() => setShowDriveModal(false)}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  );
};

export default OtherSettings;
