import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import DriveModal from "./driveModal";

const ReferenceFile2 = ({ plf, setReferenceFileOut, workflow }) => {
  const [references, setReferences] = useState([]);
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [activeChoosenInput, setActiveChoosenInput] = useState();




  useEffect(() => {
    let _references = [];
    workflow?.steps.reference?.settings.forEach((item) => {
      item.value = item.test_input;
      _references.push(item);
    });
    setReferences(_references);
    setReferenceFileOut(_references);
  }, [workflow]);

  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    try {
      let selectedRow = Object.keys(rowSelection);
      selectedRow = parseInt(selectedRow[0]);

    let x = plf[selectedRow];
    if (!x) return;
    let _references = references.map((item) => {
        if (item.name === activeChoosenInput.name) {
          return { ...item, value: x.file_path };
        }
        return item;
      })
      setReferences(_references);
      setReferenceFileOut(_references);
    } catch (error) {
      console.log(error);
    }
  }, [rowSelection]);

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        {/* <div className="text-block-50">{"stepInfo"}</div> */}
        <div className="text-block-50"></div>
        <div className="div-block-95-copy">
          {references.map((reference, index) => (
            <div
              className="div-block-96"
              style={{
                display: "flex",
              }}
            >
              <div
                className="text-block-52"
                style={{
                  display: "block",
                }}
              >
                {reference.name.charAt(0).toUpperCase() +
                  reference.name.slice(1)}{" "}
                - <br />
                <b>{reference?.value.split("/").pop()}</b>
              </div>
              {handleRefSelect.length > 0 ? (
                <div
                  className="reference-dropdown"
                  onClick={() => setShowDriveModal(true)}
                >
                  {handleRefSelect?.selectedRows[0]?.file_name}
                </div>
              ) : (
                ""
              )}
              <div className="div-block-98">
                <Select
                  value={{ value: reference.value, label: reference.value }}
                  onChange={(selectedOption) => {
                    const updatedReferences = [...references];
                    updatedReferences[index].value = selectedOption.value;
                    setReferences(updatedReferences);
                    setReferenceFileOut(updatedReferences);
                  }}
                  options={reference.options}
                  className={"reference-dropdown"}
                  styles={{
                    width: "100%",
                  }}
                  isDisabled={handleRefSelect.length === 0 ? false : true}
                  placeholder="Reference Files"
                  menuPosition="fixed"
                  menuPlacement="auto"
                />

                <div
                  // className="text-block-53"
                  className="btn-1 type-2"
                  style={{
                    marginLeft: "14px",
                    display: reference.interface_type.includes(
                      "drive_file_selector"
                    )
                      ? "flex"
                      : "none",
                  }}
                  onClick={() => {
                    setShowDriveModal(true);
                    setActiveChoosenInput(reference);
                  }}
                >
                  <div
                    className="btn-1-text"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Choose From <br />
                    Storage
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DriveModal
        show={showDriveModal}
        handleClose={() => setShowDriveModal(false)}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  );
};

export default ReferenceFile2;
