import React, { useState,  useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import "./internaladmin.css";
import { FaRegWindowClose } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { getAdminUsers, updateAdminUser } from '../../actions/InternalAdmin/actionCreators';

function EditUser(props) {

  const { editdata } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.projects.currentUser);

  const initialFormValues = useMemo(() => ({
    name: editdata?.name,
    billing_email: editdata?.billing_email,
    org_address: editdata?.org_address,
    first_name: editdata?.users[0].first_name,
    last_name: editdata?.users[0].last_name,
    email: editdata?.users[0].email,
    phone: editdata?.users[0].phone,
    is_disabled: editdata?.is_disabled,
    enable_domain: editdata?.enable_domain ?? false,
    orgs_id: editdata?._id,
    user_id: editdata?.users[0]._id,
    updated_at: '',
  }), [editdata]);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});

  const handlechange = (e, filed) => {
    if (filed === "enable_domain") {
      setFormValues(prevValues => ({ ...prevValues, [filed]: !e }));
      console.log("checkbox", !e);
    }
    else {
      setFormValues(prevValues => ({ ...prevValues, [e.target.name]: e.target.value }));
    }
  }

  const validateForm = () => {
    const errors = {};
    const ref = /^[a-zA-Z]+$/;
    const eml = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formValues.name) {
      errors.name = 'Field is empty';
    }
    // Add other validation rules here
    if (!formValues.billing_email) {
      errors.billing_email = 'Field is empty'
    } else if (!eml.test(formValues.billing_email)) {
      errors.billing_email = 'Enter valid Email'
    }
    if (!formValues.org_address) {
      errors.org_address = 'Field is empty'
    }
    if (!formValues.email) {
      errors.email = 'Field is empty'
    } else if (!eml.test(formValues.email)) {
      errors.email = 'Enter valid Email'
    }

    if (!formValues.first_name) {
      errors.first_name = 'Field is empty'
    } else if (!ref.test(formValues.first_name)) {
      errors.first_name = 'Alphabets only'
    }

    if (!formValues.last_name) {
      errors.last_name = 'Field is empty'
    } else if (!ref.test(formValues.last_name)) {
      errors.last_name = 'Alphabets only'
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      let formData = {
        ...formValues,
        updated_at: Math.floor(Date.now() / 1000),
        updated_by: currentUser.id,
      };

      dispatch(updateAdminUser(formData))
        .then(() => {
          dispatch(getAdminUsers());
          props.onHide(false);
        })
        .catch((error) => {
          console.error('Error updating user:', error);
        });
    }
  };

  return (
    <Modal
      className="org_modal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal_close">
          {' '}
          <FaRegWindowClose className="closeSVG" onClick={props.onHide} />
        </div>
        <h4 className="text-center">Update User</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="name">Organization</label>
              <input
                type="text"
                className="form-control"
                placeholder="Organization"
                onChange={handlechange}
                name="name"
                value={formValues.name}
              />
              <span className="text-danger"> {formErrors.name} </span>
            </div>
            <div className="col">
              <label htmlFor="billing_email">Org Billing Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Org Billing Email"
                onChange={handlechange}
                name="billing_email"
                value={formValues.billing_email}
              />
              <span className="text-danger"> {formErrors.billing_email} </span>
            </div>
          </div>
          <div className="row m-3">

            <div className="col">
              <label htmlFor="org_address">Org Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Org Address"
                onChange={handlechange}
                name="org_address"
                value={formValues.org_address}
              />
              <span className="text-danger"> {formErrors.org_address} </span>
            </div>
            <div className="col">
              <label htmlFor="email">Administrator Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator Email"
                onChange={handlechange}
                name="email"
                value={formValues.email}
              />
              <span className="text-danger"> {formErrors.email} </span>
            </div>
          </div>

          <div className="row m-3">
            <div className="col">
              <label htmlFor="first_name">Administrator First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator First Name"
                onChange={handlechange}
                name="first_name"
                value={formValues.first_name}
              />
              <span className="text-danger"> {formErrors.first_name} </span>
            </div>
            <div className="col">
              <label htmlFor="last_name">Administrator Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator Last Name"
                onChange={handlechange}
                name="last_name"
                value={formValues.last_name}
              />
              <span className="text-danger"> {formErrors.last_name} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
            <label htmlFor="non-institutional">Allow users to signup with org domain</label>
             
              <BootstrapSwitchButton
                data-onstyle="outline-primary"
                data-offstyle="outline-secondary"
                onlabel="Yes"
                offlabel="No"
                checked={formValues.enable_domain}               
                onChange={(e) =>
                  handlechange(!e, "enable_domain")
                }

              />
            </div>
          </div>
          <div className="text-center">
            <button onClick={handleSubmit} className="btn btn-primary ia-btn  m-3">
              Update User
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
export default EditUser
