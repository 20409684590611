import { GET_FILES } from "../actions/types"

const fileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FILES:
      let files = action.payload
      files = files.map(file => {
        file.tags = []
        if (file.file_tags && file.file_tags.length) {
          let [tags] = file.file_tags
          file.tags = Object.keys(tags).map(tag => tags[[tag]]).flat() || []
        }
        file.file_size = file.file_size < (1024 * 1024) ? file.file_size / 1024 + ' KB' : file.file_size / (1024 * 1024) + ' MB'
        return file
      })
      return { ...state, userFiles: files }
    default:
      return state
  }
}

export default fileReducer