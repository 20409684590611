import React from 'react';
import Overview from '../components/projects/Overview.component';
import Summary from '../components/projects/Summary.component';
import Files from '../components/projects/Files.component';
import Tags from '../components/Tag/Tags.component';
import Analysis from '../components/projects/analysis/Analysis.component';
import ProtectedRoute from './protected.routes'
import Output from '../components/projects/output/Output.component';
import IGVBrowser from '../components/projects/output/igv/igv';
import Internaladmin from '../components/internalAdmin/InternalAdmin'
import LayoutComponent from '../components/userAdmin/LayoutComponent'
import Divisions from '../components/userAdmin/Division/Divisions';
import AdminAddUser from '../components/userAdmin/Administration/AddUser';
import AdminAddDivision from '../components/userAdmin/Division/AddDivision';
import AdminUpdateDivision from '../components/userAdmin/Division/UpdateDivision';
import AdminUpdateuser from '../components/userAdmin/Administration/Updateuser';
import AddDivisionUser from '../components/userAdmin/Division/AddDivisionUser';
import UpdateDivisionUser from '../components/userAdmin/Division/UpdateDivisionUser';
import AddBillingAccount from '../components/userAdmin/BillingAccount/AddbillingAccount';
import UpdateBilling from '../components/userAdmin/BillingAccount/UpdateBilling';
import UpdateSubBilling from '../components/userAdmin/BillingAccount/UpdateSubBilling';

export default <React.Fragment>
  <ProtectedRoute exact path='/' component={Overview} />
  <ProtectedRoute exact path='/:id/summary' component={Summary} />
  <ProtectedRoute exact path='/:id/files' component={Files} />
  <ProtectedRoute exact path='/:id/tag' component={Tags} />
  <ProtectedRoute exact path='/:id/analysis' component={Analysis} />
  <ProtectedRoute exact path='/:id/output' component={Output} />
  <ProtectedRoute exact path='/internaladmin' component={Internaladmin} />
  <ProtectedRoute path='/admin/users' component={LayoutComponent} />
  <ProtectedRoute exact path='/admin/users/add-user' component={AdminAddUser} />
  <ProtectedRoute exact path='/admin/users/update-user' component={AdminUpdateuser} />
  <ProtectedRoute exact path='/admin/users/add-division' component={AdminAddDivision} />
  <ProtectedRoute exact path='/admin/users/update-division' component={AdminUpdateDivision} />
  <ProtectedRoute exact path='/divisions' component={Divisions} />
  <ProtectedRoute exact path='/admin/users/add-division-user' component={AddDivisionUser} />
  <ProtectedRoute exact path='/admin/users/update-division-user' component={UpdateDivisionUser} />
  <ProtectedRoute exact path='/igv' component={IGVBrowser} />

  <ProtectedRoute exact path='/admin/users/add-billing' component={AddBillingAccount} />
  <ProtectedRoute exact path='/admin/users/update-billing' component={UpdateBilling} />
  <ProtectedRoute exact path='/admin/users/update-sub-billing' component={UpdateSubBilling} />
</React.Fragment>

