import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

import { updateDimension, removeDimension } from '../../actions/projects/experiment'

import AddDimension from '../modals/AddDimension.modal'
import { connect } from 'react-redux';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#dee7ff' : '#dee7ff',
  borderRadius: '8px',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '' : 'lightgrey',
  padding: grid,
  width: 270,
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '550px',
});

class FilesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      propsCols: {},
      untagged: [],
      showAddDimensionModal: false,
      projectId: this.props.projectId,
      filter: ''
    }
    this.randomKey = `dimension-${Math.random().toString(36).substring(7)}`
  }
  async componentDidMount() {
    const { dimensions } = this.props
    await this.setDimensions(dimensions)
  }

  setDimensions = (dimensions) => {
    let state = {}
    let dimensionsMap = {}
    dimensions.forEach(dimension => {
      dimensionsMap[[dimension.dimensionName]] = dimension._id

      state[[dimension.dimensionName]] = []

      dimension.files.forEach(file => {
        state[[dimension.dimensionName]].push({
          id: file._id,
          content: file.file_name,
          color: file.file_color
        })
      })
    })
    this.setState({
      dimensionsMap: dimensionsMap,
      propsCols: {
        ...this.state.propsCols,
        ...state
      },
      untagged: state.untagged
    }, () => console.log(this.state))
  }
  getList = id => this.state.propsCols[[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );


      this.setState({
        propsCols: {
          ...this.state.propsCols,
          [source.droppableId]: items
        }
      }, () => this.updateDimensions());
    } else {

      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        propsCols: {
          ...this.state.propsCols,
          [source.droppableId]: result[[source.droppableId]],
          [destination.droppableId]: result[[destination.droppableId]]
        }
      }, () => {
        this.updateDimensions()
      });
    }
  };
  updateDimensions = async () => {
    let dimensions = _.values(_.mapValues(this.state.propsCols,
      (values, key) => ({ dimensionId: this.state.dimensionsMap[key], files: _.map(values, 'id') })))
    this.setState({
      untagged: this.state.propsCols.untagged
    })
    await this.props.updateDimension(this.state.projectId, dimensions)
  }
  openDimensionModal = () => {
    this.setState({
      showAddDimensionModal: true
    })
  }
  handleDimensionModalClose = (update = false) => {
    this.setState({
      showAddDimensionModal: false
    })
    if (update) {
      // this.randomKey = `dimension-${Math.random().toString(36).substring(7)}`
      // this.props.experiment.map(e => e.variables.map(v => {
      //   if (v._id === this.props.variableId) {
      //     this.setDimensions(v.dimensions)
      //   }
      // }))
      console.log(this.props.dimension)
      this.setState({
        propsCols: {
          ...this.state.propsCols,
          [this.props.dimension.dimensionName]: []
        },
        dimensionsMap: {
          ...this.state.dimensionsMap,
          [this.props.dimension.dimensionName]: this.props.dimension._id
        }
      }, () => console.log(this.state))
    }

  }

  removeDimension = async (id) => {
    const files = this.state.propsCols[[id]]
    let fileIds = [..._.map(this.state.propsCols.untagged, 'id'), ..._.map(files, 'id')]

    const state = _.omitBy(this.state.propsCols, (s, propsCol) => propsCol === id)
    state.untagged = [...this.state.propsCols.untagged, ...files]
    await this.props.updateDimension(
      this.state.projectId,
      [{
        dimensionId: this.state.dimensionsMap.untagged,
        files: fileIds
      }]
    )
    await this.props.removeDimension({
      projectId: this.state.projectId,
      dimensionId: this.state.dimensionsMap[[id]]
    })
    this.setState({
      untagged: state.untagged,
      propsCols: state
    })
  }
  filterFiles = ({ target: { value } }) => {
    this.setState({
      filter: value
    })
    if (value.trim().length) {
      let files = this.state.propsCols.untagged.filter(file => file.content.includes(value))
      this.setState({
        propsCols: {
          ...this.state.propsCols,
          untagged: files
        }
      })
    }
    else {
      this.setState({
        propsCols: {
          ...this.state.propsCols,
          untagged: this.state.untagged
        }
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="variable-action add-dimension flex-right">
          <FontAwesomeIcon icon={faPlus} onClick={this.openDimensionModal} title='Add Dimension' />
        </div>
        {this.state.showAddDimensionModal ?
          <AddDimension
            experimentId={this.props.experimentId}
            variableId={this.props.variableId}
            showModal={this.state.showAddDimensionModal}
            handleClose={this.handleDimensionModalClose}
          />
          :
          <React.Fragment></React.Fragment>
        }

        <div className='files'>
          <div className='content-area'>

            <DragDropContext onDragEnd={this.onDragEnd}>
              {Object.keys(this.state.propsCols).map(id => (
                <section className='list' key={id}>
                  <div className='dimension-header'>
                    <p className='dimension'>{id}</p>
                    {
                      id === 'untagged' ? <React.Fragment></React.Fragment> :
                        <span className="remove-dimension flex-right">
                          <FontAwesomeIcon icon={faTrash} onClick={() => this.removeDimension(id)} />
                        </span>
                    }
                  </div>
                  {id === 'untagged' ?
                    <React.Fragment>
                      <div className='filter'>
                        Filter: <input type='text' value={this.state.filter} onChange={this.filterFiles} />
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                  }
                  <Droppable key={id} droppableId={id}>

                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {this.state.propsCols[[id]].map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}>
                            {(provided, snapshot) => (
                              <div
                                className='file'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                {item.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </section>
              ))}
            </DragDropContext>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  experiment: state.experiment.experiment,
  dimension: state.experiment.dimension
})
export default connect(mapStateToProps, { updateDimension, removeDimension })(FilesList)

