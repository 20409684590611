import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import Swal from 'sweetalert2'

import { addExpirment } from '../../actions/projects/experiment'


import Loader from '../loader/Loader.component';


const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,

})


class AddExperiment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }

  }
  async componentDidMount() {
    this.setState({
      show: this.props.showModal
    })

  }
  createExpirment = async (vals) => {
    vals.projectId = this.props.projectId
    await this.props.addExpirment(vals)
    this.handleClose()
  }

  handleClose = () => {
    this.setState({
      show: false,
    })
    this.props.handleClose()
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Add Experiment</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='exp-name'>
                <Form.Label>Name</Form.Label>
                <Field
                  name="experimentName"
                  id='exp-name'
                  component='input'
                  type='text'
                  placeholder='Expirment Name'
                >
                </Field>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' variant="primary" onClick={this.props.handleSubmit(this.createExpirment)}>
              Create Experiment
            </Button>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
          </Button>

          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  files: state.files.userFiles
})
export default connect(mapStateToProps, { addExpirment })(reduxForm({ form: 'addExperiment' })(AddExperiment))