import React, { useState, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateDivision } from "../../../actions/userAdmin/actionCreators";
import { useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function DivisionUpdate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentUser = useSelector((state) => state.projects.currentUser);

  const initialFormValues = useMemo(() => ({
    id: location.state.division._id,
    division_name: location.state.division.division_name.trim(),
    organisation_id: location.state.division.organisation_id,
    updated_by: currentUser.id,
    updated_at: Math.floor(Date.now() / 1000)
  }), [currentUser, location.state]);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});

  const handlechange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  }, []);

  const validateForm = useCallback(() => {
    const errors = {};
    const ref = /^[a-zA-Z0-9\s!@#$%^&*()-_=+[\]{}|;:'",.<>/?\\]*$/;

    if (!formValues.division_name.trim()) {
      errors.division_name = "Field is empty";
    } else if (!ref.test(formValues.division_name)) {
      errors.division_name = "Invalid format";
    }
    if (!formValues.organisation_id) {
      errors.organisation_id = "Field is empty";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formValues]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {
      ...formValues
    };

    if (validateForm()) {
      dispatch(updateDivision(formData))
        .then(() => {
          history.push("/admin/users/divisions");
          setFormValues(initialFormValues);
        })
        .catch((error) => {
          console.error('Error updating division:', error);
        });
    }
  };

  const handleBack = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  return (
    <div className="mod">
      <Modal.Body>
        <div className="pull"> </div>
        <h4 className="text-center">Update Division</h4>
        <form>
          <div
            className="row m-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-6 ">
              <label htmlFor="division_name">Division Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Division Name"
                onChange={handlechange}
                name="division_name"
                value={formValues.division_name}
              />
              <span className="text-danger"> {formErrors.division_name}</span>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="add-btn btn btn-primary  m-3"
            >
              Update division
            </button>
            <button
              type="button"
              onClick={handleBack}
              className="add-btn btn btn-primary  m-3"
            >
              Back
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}
export default DivisionUpdate;
