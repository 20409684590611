import React, { useState, useEffect } from "react";
import { analysisUpdateReport, analysisAddReport } from "../../../actions/projects";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Reports = (props) => {
  const dispatch = useDispatch();
  const [allFigures, setAllFigures] = useState([]);
  const [updateDescTab, setUpdateDescTab] = useState(null);
  const [editorContent, setEditorContent] = useState(null);
  const reports = useSelector((state) => state.projects.reports);
  const currentUser = useSelector((state) => state.projects.currentUser);


  const updateDescription = () => {
    let details = editorContent["details"];
    let index = editorContent["index"];
    console.log("updateDescription", details, index);
    let tmpAllFigs = [
      ...allFigures.slice(0, index),
      {
        graph: allFigures[index].graph,
        time: allFigures[index].time,
        details: details,
      },
      ...allFigures.slice(index + 1),
    ];

    let _report = {
        graph: allFigures[index].graph,
        time: allFigures[index].time,
        details: details,
      }


    setAllFigures(tmpAllFigs);
    setUpdateDescTab(null);
    dispatch(analysisUpdateReport(props.pipeline_info.run_id, _report, index));
    toast.success("Report Updated");
  };

  const addNewNote = () => {
    let n_report = {
        user: currentUser?.name,
        details: "Add notes here...",
        graphSrc:
          "https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1).png",
        time: Date.now(),
      }
    setAllFigures((n) => [
      ...n,
      n_report
      ,
    ]);

    dispatch(analysisAddReport(props.pipeline_info.run_id, n_report));

  };

 

  useEffect(() => {
    console.log("reports", reports);
    setAllFigures(reports);
  }, [reports]);

  return (
    <div className="div-block-81">
      {allFigures &&
        allFigures?.map((aReport, i) => {
          let src = null;
          try {
            src = JSON.parse(aReport?.graph).imageData;
          } catch (e) {
            src = null;
          }

          return (
            <div
              className="div-block-79"
              style={{
                borderRadius: "5px",
              }}
            >
              <div className="div-block-83">
                <img
                  src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60faa814615ddfa0152aee25_profile.jpg"
                  loading="lazy"
                  width={28}
                  alt=""
                  className="image-6"
                />
                <div className="text-block-46">
                  <b>{aReport.user}</b>
                </div>
                <div className="text-block-47">
                  {moment.unix(aReport?.time / 1000).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="div-block-80">
                {/* <InlineSVG src={aReport.graph}  element="div" className="image-7"/> */}

                {/* <img
                    // src={`data:image/svg+xml;utf8,${rt1}`}
                    src={`data:image/svg+xml;utf8,${aReport.graph}`}
                    // src={`data:image/svg+xml;utf8,${getImgs(aReport.graphSrc)}`}
                    loading="lazy"
                    width={593}
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 44vw, (max-width: 1919px) 43vw, 593px"
                    // srcSet="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1)-p-500.png 500w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1)-p-800.png 800w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1).png 1125w"
                    alt=""
                    className="image-7"
                  /> */}
                {src?.length > 0 && (
                  <img
                    // src={`data:image/svg+xml;utf8,${rt1}`}
                    // src={aReport.graphSrc}
                    src={src}
                    loading="lazy"
                    width={593}
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 44vw, (max-width: 1919px) 43vw, 593px"
                    // srcSet="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1)-p-500.png 500w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1)-p-800.png 800w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/6234186086057f40d8880054_diagram%20(1).png 1125w"
                    alt=""
                    className="image-7"
                  />
                )}
              </div>
              <div className="text-block-45">
                {updateDescTab === i ? (
                  <Editor
                    apiKey="ikvo3veoe3hy4ek9bvz5m9gljlmagaqhljvc7wayqj2k00e5"
                    toolbar="undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                    onBlur={(x, source, hh) => {
                      if (source === "silent") return; // ReactQuill paste issue
                      updateDescription();
                    }}
                    // onHide={(a, v) => {
                    //   console.log('hidee', a, v)

                    // }}
                    onEditorChange={(content, editor) => {
                      setEditorContent({ details: content, index: i });
                    }}
                    initialValue={aReport.details}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      // menubar: "file edit view insert format tools table help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                ) : (
                  <>
                    <div
                      className="div-block-51 report"
                      onClick={() => {
                        setUpdateDescTab(i);
                      }}
                    >
                      Edit
                    </div>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: aReport.details }} />
                  </>
                )}
              </div>
            </div>
          );
        })}

      <div className="add-notes-button" onClick={() => addNewNote()}>
        Click To Add Notes
      </div>
    </div>
  );
};

export default Reports;
