import React from "react";
import logo from "../../assets/logo.svg";
import logoBlue from "../../assets/images/logo_blue.svg";
import profileImage from "../../assets/profile.jpg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./Header.css";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

const Header = () => {
  // const [responsive, setResponsive] = React.useState(false);
  const history = useHistory();
  const currentUser = useSelector((state) => state.projects.currentUser);

  const handleAdminPage = () => {
    if (currentUser.roles === "internal admin") {
      history.push("/internaladmin")
    } else if (currentUser.roles === "company admin") {
      history.push("/admin/users/administration")
    } else if (currentUser.roles === "division admin") {
      history.push("/admin/users/divisions")
    } else {
      history.push("/admin/users/billing")
    }
  }

  // const navFunction = () => {
  //   setResponsive((responsive) => !responsive)
  // };

  const handleLogout = () => {
    Cookies.remove("SessionID");
    window.location = `https://drive.skygenic.com/#!/login`;
  }

  return (
    <React.Fragment>
      <header>
        <div className="main">
          <div className="logo">
            <img src={logo} alt="SkyGenic Logo" />
          </div>

          <ul className="main_toggle">
            {/* <li><Link to="#" className="dropdown">Analysis</Link></li> */}
            <li className="selected">
              <Link to="#" className="dropdown">
                Analysis
              </Link>
              <ul>
                <li>
                  <a href="https://drive.skygenic.com/skygenic#!/user_file_manager" className="selected">
                    Storage
                  </a>
                </li>
                <li>
                  <Link to="/">Analysis</Link>
                </li>
              </ul>
            </li>
          </ul>

          <div className="profile">
            <button type="button" id="profile" className="dropdown">
              {currentUser?.name ? currentUser.name : ""}
            </button>
            <img src={profileImage} alt="profile" />
            <ul>
              <li>
                <img src={logoBlue} alt="SkyGenic Logo" />
              </li>
              <li>
                <strong>Hello {currentUser?.name ? currentUser.name : ""}</strong>
              </li>
              {currentUser?.roles !== "view only" ? (<li>
                <span onClick={handleAdminPage} className="admin_menu"
                >Admin</span>
              </li>) : null}
              <li>
                <span href="skygenic#!/user_profile" className="admin_menu">Edit My Profile</span>
              </li>
              <li>
                <span className="admin_menu" onClick={handleLogout}>Log Out</span>
              </li>
              <li className="small">
                <a
                  href="https://skygenic.com/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                •
                <a
                  href="https://skygenic.com/terms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
                •
                <a
                  href="https://skygenic.com/hipaa-business-associate-agreement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  BAA Agreement
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header