import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
//import _ from 'lodash'
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { MaterialReactTable } from "material-react-table";

import {
  addFilesToProject,
  getAllCollaboratorsFiles,
} from "../../actions/projects";
import { getUserFiles, getFiles } from "../../actions/files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import fontawesome regual folder icons
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import Loader from "../loader/Loader.component";

function round(num, decimalPlaces = 0) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}

const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
});

// Convert src\components\modals\AddFiles.modal.jsx from class based component to functional component

const AddFiles = ({ projectId, selectedFileIds, showModal, onHide }) => {
  const dispatch = useDispatch();
  // const projectFiles = useSelector((state) => state.projects.projectFiles);
  // const userFiles2 = useSelector((state) => state.files.userFiles);
  const userFiles = useSelector((state) => state.projects.collaboratorsFiles);
  const [rowSelection, setRowSelection] = useState({});
  const [rowSelectionMemo, setRowSelectionMemo] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const [currentPath, setCurrentPath] = useState(["My Drive"]);

  // console.log(userFiles, "----------", userFiles2);
  const columns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      Cell: ({ renderedCellValue, row }) =>
        row.original?.is_directory ? (
          <strong
            className="file"
            style={{
              width: "100%",
            }}
            // onClick={() => {
            //   setCurrentPath((y) => [...y, row.original.file_name]);
            // }}
          >
            <FontAwesomeIcon icon={faFolder} />
            &nbsp;&nbsp;&nbsp;{renderedCellValue}
          </strong>
        ) : (
          renderedCellValue
        ),
    },
    {
      header: "User Name",
      accessorKey: "owner_nickname",
      sortable: true,
    },
    {
      header: "Tags",
      accessorKey: "tags",
      Cell: ({ renderedCellValue }) => (
        <span style={{ margin: "5px" }}>{renderedCellValue}</span>
      ),
      wrap: true,
    },
    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => {
        if(renderedCellValue ){

        return (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
          
        )
        } else {
          return <div></div>
        }
      },
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        // renderedCellValue is in bytes, convert to Bytes, MB & GB

        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },
  ]);

  const handleClose = () => {
    let selectedIds = userFiles.filter(
      (f) =>
        Object.keys(rowSelection).includes(f._id) && f.is_directory !== true
    );
    console.log(selectedIds, "selectedFileIds");
    console.log(currentUser)
    dispatch(addFilesToProject(projectId, { files: selectedIds, userId: currentUser.id, owner_nickname: currentUser.name}));
    toast.fire({
      icon: "success",
      title: "Files added to project",
    });
    onHide();
  };

  useEffect(() => {
    console.log(userFiles, "userFiles");

    if (!userFiles) {
      setLoadingFiles(true);
      // dispatch(getUserFiles(currentUser));
      dispatch(getAllCollaboratorsFiles(projectId));
    } else {
      let dir_file;
      console.log(currentPath, "currentPath");
      // if (currentPath.length > 1) {
      let _path;
      if (currentPath.length < 1) {
        _path = "";
      } else {
        _path = currentPath.slice(1).join("/");
      }
      // let _path = currentPath.slice(1).join("/")
      console.log(_path, "_path");
      dir_file = userFiles.filter((x) => x.imaginary_file_directory === _path);
      console.log(dir_file, "dir_file");
      setFilteredFiles(dir_file);
      // } else {
      //   setFilteredFiles(userFiles);
      // }

      setLoadingFiles(false);
      const obj = selectedFileIds.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

      setRowSelection(obj);
    }
  }, [userFiles, currentPath]);

  useEffect(() => {
    // setRowSelectionMemo to keys from rowSelection as array, add items from previous rowSelectionMemo, and filter is_directory is true
    console.log(rowSelection, "rowSelection");
    let _rowSelectionMemo = Object.keys(rowSelection).filter((x) => {
      return userFiles?.some((y) => y._id === x && y.is_directory !== true);
    });

    setRowSelectionMemo(_rowSelectionMemo);
    userFiles &&
      userFiles?.map((uf) => {
        if (_rowSelectionMemo.includes(uf._id)) {
        }
      });

    if (_rowSelectionMemo.length > 0) {
      let _rowSelection = _rowSelectionMemo.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
      setRowSelection(_rowSelection);
      console.log(_rowSelection, "_rowSelection");
    }
  }, [currentPath]);

  useEffect(() => {
    console.log(
      rowSelection,
      "rowSelection",
      selectedFileIds,
      "selectedFileIds"
    );

    console.log(Object.keys(rowSelection).length)
    // check if rowSelection has keys (_id) with path not equal to full_file_path

    if (Object.keys(rowSelection).length > 0) {
      filteredFiles.map((x) => {
        if (Object.keys(rowSelection).includes(x._id)) {
          if (x.is_directory && !currentPath.includes(x.file_name)) {
            setCurrentPath((y) => [...y, x.file_name]);
          }
        }
      });
    }
  }, [rowSelection]);

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        show={showModal}
        onHide={handleClose}
        size="lg"
        centered
        style={{
          overflowY: "auto",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input
            placeholder="Filter"
            style={{
              width: "20%",
              padding: "10px",
              margin: "15px",
              border: "none",
              borderRadius: "5px",
              marginBottom: "10px",
              outline: "none",
              backgroundColor: "#d0d2db",
            }}
            onChange={(e) => {
              // filter filteredFiles on innerText
              const filtered = userFiles.filter((x) =>
                x.file_name.toLowerCase().includes(e.target.value.toLowerCase())
              );
              setFilteredFiles(filtered);
            }}
          ></input> */}
          {loadingFiles ? (
            <div className="relative-loader">
              <Loader />
            </div>
          ) : (
            <>
              <div
                style={{
                  padding: "10px",
                  paddingTop: "0px",
                }}
              >
                {currentPath.length > 1 &&
                  currentPath.map((x, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          marginRight: "5px",
                          cursor: "pointer",
                          color: "#0A1352",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontFamily: "Open Sans, sans-serif",
                        }}
                        onClick={() => {
                          setCurrentPath((x) => x.slice(0, index + 1));
                        }}
                      >
                        {x}&nbsp;/&nbsp;
                      </div>
                    );
                  })}
                {/* <br />
                <br /> */}

                <MaterialReactTable
                  columns={columns}
                  data={filteredFiles.sort(
                    (a, b) => b.is_directory - a.is_directory
                  )}
                  // isLoading={loadingFiles}
                  enableGrouping
                  enableStickyHeader
                  enableRowSelection
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 15, pageIndex: 0}
                  }}
                  selectAllMode="all"
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: "pointer" },
                  })}
                  getRowId={(originalRow) => originalRow._id}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div></div>
          <Button
            variant="secondary"
            style={{
              marginRight: "20px",
            }}
            onClick={handleClose}
          >
            Update Files
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddFiles;
