import api from '../../api/projects'
import {
  GET_EXPIRMENT, ADD_EXPIRMENT,
  ADD_VARIABLE, REMOVE_VARIABLE,
  ADD_DIMENSION, UPDATE_DIMENSION, REMOVE_DIMENSION
} from '../types'



export const getExpirment = (id) => async dispatch => {
  const experiment = await api.get(`/projects/${id}/exp-design`)

  dispatch({ type: GET_EXPIRMENT, payload: experiment.data })
}

export const addExpirment = (data) => async dispatch => {
  const experiment = await api.post(`/projects/${data.projectId}/exp-design`, { experiment: data })
  dispatch({ type: ADD_EXPIRMENT, payload: experiment.data })

}
export const addVariable = (data) => async dispatch => {
  const results = await api.post(`/projects/${data.projectId}/exp-design/variable`, { data })
  // dispatch({ type: ADD_EXPIRMENT, payload: results.data })
  dispatch({ type: ADD_VARIABLE, payload: results.data })
}
export const removeVariable = (projectId, variableId) => async dispatch => {
  const results = await api.delete(`/projects/${projectId}/exp-design/variable/${variableId}`)
  dispatch({ type: REMOVE_VARIABLE, payload: results.data })
}
export const addDimension = (data) => async dispatch => {
  const results = await api.post(`/projects/${data.projectId}/exp-design/dimension`, { data })
  dispatch({ type: ADD_DIMENSION, payload: results.data })
}
export const updateDimension = (projectId, data) => async dispatch => {
  const results = await api.patch(`/projects/${projectId}/exp-design/dimension`, { data })
  dispatch({ type: UPDATE_DIMENSION, payload: results.data })
}
export const removeDimension = (data) => async dispatch => {
  const results = await api.delete(`/projects/${data.projectId}/exp-design/dimension/${data.dimensionId}`)
  dispatch({ type: REMOVE_DIMENSION, payload: results.data })
}
