import React, { Component } from 'react';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import AddVariable from '../modals/AddVariables.modal'



/* Variables | Components */
class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      style: {},
      tabs: this.props.tabsList,
      responsiveTabs: [],
      dropdownStatus: false,
      showAddVariableModal: false,
      projectId: this.props.projectId,
      experimentId: this.props.experimentId
    };
  }


  componentDidMount() {
    this.spliceTabs();
    window.addEventListener('resize', _.throttle(this.update, 1000));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', _.throttle(this.update, 1000));
  }

  spliceTabs = () => {
    let totalWidth = 0;
    let barWidth = this.tabs.clientWidth - 44;
    console.log('barWidth', this.tabs.clientWidth)

    const el = document.getElementsByClassName('tab__item');

    for (let i = 0; i < el.length; i++) {
      const tabWidth = el[i].clientWidth;
      // 44 = dropdown toggle width
      if (i === (el.length - 1)) {
        barWidth += 44;
      }

      if ((totalWidth + tabWidth) > barWidth) {
        const tabs = this.state.tabs;
        const responsiveTabs = tabs.splice(i, tabs.length);
        this.setState({ responsiveTabs }, () => {
          this.setStyle();
        });
        break;
      }
      totalWidth += tabWidth;
    }
    this.setStyle();
  }

  update = () => {
    this.setState(prevState => {
      return {
        tabs: prevState.tabs.concat(prevState.responsiveTabs),
        responsiveTabs: []
      }
    }, () => {
      this.spliceTabs();
    })
  }

  setStyle = () => {
    const { active, tabs } = this.state;
    let width = null;
    let left = 0;
    if (active < tabs.length) {
      const el = document.getElementsByClassName('tab__item');

      for (let i = 0; i < el.length; i++) {
        let tabWidth = el[i].clientWidth;
        if (i === active) {
          width = tabWidth;
          break;
        } else {
          left += tabWidth;
        }
      }
    }

    this.setState({
      style: { width, left }
    });
  }

  changeTab = index => {
    this.setState({ active: index, dropdownStatus: false }, () => {
      this.setStyle();
    });
  }

  toggleDropdown = () => {
    this.setState(prevState => {
      return { dropdownStatus: !prevState.dropdownStatus }
    });
  }

  handleVariableModal = () => {
    this.setState({
      showAddVariableModal: true
    })
  }
  handleVariableModalClose = (update = false) => {
    console.log(update)
    this.setState({
      showAddVariableModal: false
    })
    if (update) {
      this.props.handleChange()
    }
  }

  removeTab = async () => {
    Swal.fire({
      title: 'Confirmation',
      text: `Do you want to delete "${this.state.tabs[this.state.active].variableName}"`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#dd6b55',
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then(result => {
      if (result.isConfirmed) {
        this.props.removeVariable(this.state.active)
      }
    })

  }
  render() {
    const { active, style, tabs, responsiveTabs, dropdownStatus } = this.state;
    const { children } = this.props;
    return (
      <React.Fragment>
        <div>
          {console.log(tabs)}
          <ul className="tabs" ref={node => this.tabs = node}>
            {tabs.map((item, index) => {
              return <li onClick={() => this.changeTab(index)} key={index} className={`tab__item ${active === index ? 'active' : ''}`}>{item.variableName}</li>
            })}
            <span style={style} className="indicator"></span>
            {responsiveTabs.length > 0 && (
              <Dropdown active={dropdownStatus} toggleDropdown={() => this.toggleDropdown()}>
                {responsiveTabs.map((tab, index) => {
                  const tabIndex = tabs.length + index;
                  return (
                    <li
                      className={`tab__item ${tabIndex === active ? 'active' : ''}`}
                      onClick={() => this.changeTab(tabIndex)}
                      key={index}
                    >
                      {tab}
                    </li>
                  )
                })}
              </Dropdown>
            )}
            <div className="variable-action flex-center">
              {
                tabs.length ? <FontAwesomeIcon icon={faTrash} onClick={this.removeTab} /> : <React.Fragment></React.Fragment>
              }
              <FontAwesomeIcon icon={faPlus} onClick={this.handleVariableModal} />
            </div>
            {this.state.showAddVariableModal ?
              <AddVariable
                projectId={this.state.projectId}
                experimentId={this.state.experimentId}
                showModal={this.state.showAddVariableModal}
                handleClose={this.handleVariableModalClose}
              />
              :
              <React.Fragment></React.Fragment>
            }
          </ul>
        </div>
        {children.map((child, index) => {
          return <div key={index} className={`tab__content ${index === active ? 'active' : ''}`}>{child}</div>
        })}
      </React.Fragment>
    );
  }
}

class Dropdown extends React.Component {
  toggleDropdown = () => {
    this.props.toggleDropdown();
  }

  render() {
    const { children, active } = this.props;
    return (
      <div className={'dropdown__wrapper'}>
        <Link className={'dropdown__toggle'} to="#" onClick={this.toggleDropdown} data-toggle="dropdown">
          ...
        </Link>
        <ul className={`dropdown ${active ? 'active' : ''}`}>
          {children}
        </ul>
      </div>
    )
  }
}

export default Tabs;