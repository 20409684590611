import React, { Component } from 'react';
import { connect } from 'react-redux';

import { listProjectFiles } from '../../actions/projects'
import { getExpirment, removeVariable } from '../../actions/projects/experiment';
import FilesList from './FilesList.component'
import Tabs from './Tabs.component'
class ExperimentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      tabList: this.props.variables,
      loadingFiles: true,
    };
    this.projectId = this.props.projectId
    this.experimentId = this.props._id
  }

  async componentDidMount() {
    // window.setTimeout(() => {
    //   const el = ReactDOM.findDOMNode(this);
    //   const height = el.querySelector('.panel__inner').scrollHeight;
    //   this.setState({
    //     height
    //   });
    // }, 333);

    await this.props.listProjectFiles(this.projectId)
    this.setState({
      loadingFiles: false
    })
  }

  handleChange = async () => {

    await this.props.getExpirment(this.projectId)
    const [{ variables }] = this.props.experiment
    this.setState({
      tabList: variables
    })
  }
  removeVariable = (index) => {
    this.props.removeVariable(this.projectId, this.state.tabList[index]['_id'])
    this.setState({
      tabList: this.state.tabList.filter((_, i) => i !== index)
    })
  }
  render() {
    const { experimentName, activeTab, index } = this.props;
    // const { height } = this.state;
    const isActive = activeTab === index;
    // const innerStyle = {
    //   height: `${isActive ? height : 0}px`
    // }

    return (
      <div className='panel'
        role='tabpanel'
        aria-expanded={isActive}>
        <button className='panel__label'
          // role='tab'
          /* onClick={activateTab} */>
          {experimentName}
        </button>
        <div className='panel__inner'
          // style={innerStyle}
          aria-hidden={!isActive}>
          {/* Tabs */}
          <div className='panel__content'>
            <Tabs
              tabsList={this.state.tabList}
              handleChange={this.handleChange}
              removeVariable={this.removeVariable}
              key={this.state.tabList.length}
              projectId={this.projectId}
              experimentId={this.experimentId}
            >
              {
                this.state.tabList.map(variable => (
                  <div key={this.state.tabList.length}>
                    <FilesList
                      projectId={this.projectId}
                      variableId={variable._id}
                      dimensions={variable.dimensions}
                      experimentId={this.experimentId}
                    />
                  </div>
                ))}
            </Tabs>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  experiment: state.experiment.experiment
})

export default connect(
  mapStateToProps, { listProjectFiles, getExpirment, removeVariable }
)(ExperimentItem);