import React, { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
const DriveModal = ({
  show,
  handleClose,
  globalFilter,
  setGlobalFilter,
  data,
  rowSelection,
  setRowSelection,
}) => {

  const inputColumns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    {
      header: "Tags",
      accessorKey: "tags",
      Cell: ({ renderedCellValue }) => (
        <span style={{ margin: "5px" }}>
          {renderedCellValue.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
    // {
    //   name: "Exp Dsgn Tags",
    //   accessorKey: "tags",
    //   format: ({ tags }) => (
    //     <span style={{ margin: "5px" }}>
    //       {tags.map((tag, index) => (
    //         <span className="tag" key={index}>
    //           {tag}
    //         </span>
    //       ))}
    //     </span>
    //   ),
    //   wrap: true,
    // },

    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },

    {
      header: "Folder",
      // accessorKey: "folder",
      id: "folder",
      Cell: ({ renderedCellValue, row }) => {
        let folders = row.original.file_path.split("/");
        folders.pop();
        folders = folders.filter((folder) => folder !== "");
        console.log(folders, "folders");

        return (
          <span style={{ margin: "5px" }}>
            {folders.map((folder, index) => (
              <span className="tag" key={index}>
                {folder}
              </span>
            ))}
          </span>
        );
      },
    },
    {
      header: "Paired",
      accessorKey: "paired",
      sortable: true,
      hide: true,
    },
  ]);
  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }


  return (
    <Modal
      dialogClassName="modal-90w"
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      style={{
        overflowY: "auto",
        maxHeight: "98vh",
      }}
    >
      <>
        <div
          className="text-block-2"
          style={{
            padding: "20px 40px",
            alignSelf: "center",
          }}
        >
          Input Files
        </div>

        <div
          style={{
            width: "100%",
            padding: "10px 50px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              placeholder="Filter"
              style={{
                width: "30%",
                padding: "10px",
                margin: "10px 0px",
                border: "none",
                borderRadius: "5px",
                marginBottom: "10px",
                outline: "none",
                backgroundColor: "#d0d2db",
                color: "#000000",
              }}
              defaultValue={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            ></input>
          </div>
          <MaterialReactTable
            columns={inputColumns}
            data={data ? data : []}
            enableStickyHeader
            enableMultiRowSelection={false}
            enableRowSelection
            initialState={{
              density: "compact",
              expanded: false,
              pagination: { pageIndex: 0, pageSize: 20 },
              sorting: [{ id: "paired", desc: false }],
            }}
            state={{
              globalFilter,
              rowSelection,
            }}
            onGlobalFilterChange={setGlobalFilter}
            muiToolbarAlertBannerChipProps={{ color: "primary" }}
            muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            onRowSelectionChange={setRowSelection}
            muiTableBodyRowProps={({ row }) => ({
              onClick: row.getToggleSelectedHandler(),
              sx: { cursor: "pointer" },
            })}
          />
        </div>
      </>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <div></div>
        <Button
          variant="secondary"
          style={{
            marginRight: "20px",
          }}
          onClick={handleClose}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DriveModal;
