import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { updateSingleBillingAccount } from '../../../actions/userAdmin/actionCreators.js';
import closeCircle from '../../../assets/Circle-X-close.png';
import uparrow from "../../../assets/Chevron-Up.png";
import downarrow from "../../../assets/Chevron-Down.png";


const OutsideAlerter = ({ children, setOpen }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return <div ref={wrapperRef}>{children}</div>;
};

function AddSubBilling() {

  const dispatch = useDispatch();
  const history = useHistory();
  const navigate = useHistory();

  const singleUser = useSelector((state) => state.userAdmin.singleBillingAccount);
  const currentUser = useSelector((state) => state.projects.currentUser);
  // const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const actionbillingID = useSelector((state) => state.userAdmin.activeBillingId);
  const divisionData = useSelector((state) => state.userAdmin.division);
  // const billingData = useSelector((state) => state.userAdmin.billing);
  const [open, setOpen] = useState(false);
  const initialvalue = {
    _id: singleUser._id,
    billing_account_name: singleUser.billing_account_name,
    // division_id: singleUser.division_id,
    updated_by: currentUser.id,
    organisation_id: singleUser.organisation_id,
    created_by: singleUser.created_by,
  }
  const errors = {}
  const [formvalues, setFormvalues] = useState(initialvalue);
  const [formErrors, setFormErrors] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [options, setOptions] = useState([]);


  const filterdiv = useCallback(async () => {
    let result = await divisionData.filter(item => {
      if (currentUser.roles === "internal admin" || currentUser.roles === "company admin") {
        // return selectedOrgDiv.some(item2 => item2.divisionId == item._id)
        return item
      } else {
        return currentUser?.divisions.some(item2 => item2.divisionId === item._id)
      }
    });

    const optionsList = result.map(item => ({
      value: item._id,
      label: item.division_name
    }));

    setOptions(optionsList);
  }, [divisionData, currentUser]);

  const currentdivset = useCallback(async () => {

    let result = await divisionData.filter(item => {
      if (currentUser.roles === "internal admin" || currentUser.roles === "company admin") {
        // return selectedOrgDiv.some(item2 => item2.divisionId == item._id)
        return item
      } else {
        return currentUser?.divisions.some(item2 => item2.divisionId === item._id)
      }
    });

    let result1 = await result.filter(item => {
      return singleUser?.divisions.some(item2 => item2.divisionId === item._id)
    });

    const div = result1.map((item) => ({
      value: item._id,
      label: item.division_name
    }))
    setDivisions(div);
  }, [divisionData, currentUser, singleUser]);
  useEffect(() => {
    filterdiv();
    currentdivset();
  }, [filterdiv, currentdivset])

  const handlechange = (e) => {
    const { name, value } = e.target
    setFormvalues({ ...formvalues, [name]: value })
  }
  const formData = {
    ...formvalues,
    // created_at: Math.floor(Date.now() / 1000),
    updated_at: Math.floor(Date.now() / 1000),
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let divisionList = [];
    for (let i = 0; i < divisions.length; i++) {
      const obj = {
        "divisionId": divisions[i].value
      }
      divisionList.push(obj)
    }

    let formData1 = {
      ...formData,
      divisions: divisionList
    }

    const ref = /^[a-zA-Z0-9\s!@#$%^&*()-_=+[\]{}|;:'",.<>/?\\]*$/;
    if (!formData1.billing_account_name) {
      errors.billing_account_name = 'Field is empty'
    }
    else if (!ref.test(formData1.billing_account_name)) {
      errors.billing_account_name = 'Invalid Format'
    }
    if (!formData1.organisation_id) {
      errors.organisation_id = 'Field is empty'
    }
    if (divisionList.length === 0) {
      errors.division_id = 'Field is empty'
    }
    setFormErrors(errors);

    if (
      formData1 &&
      formData1.billing_account_name !== '' &&
      formData1.organisation_id !== '' &&
      divisionList.length !== 0 &&
      Object.keys(errors).length === 0
    ) {
      dispatch(updateSingleBillingAccount(formData1, actionbillingID))
      setTimeout(() => {
        navigate.push('/admin/users/billing-account')
        setFormvalues({
          billing_account_name: '',
          division_id: '',
          organisation_id: '',
          // created_at: '',
          // created_by: '631ef9fd6fbe123778a76206',
          updated_by: '',
          updated_at: '',
        })
      }, '300')
    }
  }


  const handleBack = (e) => {
    e.preventDefault();
    history.goBack();
  }

  const closeDropDown = () => {
    setDivisions([]);
    setOpen(false);
  }

  const handleCheckboxChange = (value) => {
    if (divisions.find((val) => val.value === value)) {
      let filterOption = divisions.filter((val) => val.value !== value);
      setDivisions(filterOption);
    } else {
      let obj = {
        value: value,
        label: value // You can set the label based on your requirements
      }
      setDivisions([...divisions, obj]);
    }
  };
  return (
    <div className="mod">
      <Modal.Body className="">
        <div className="pull"> </div>
        <h4 className="text-center">Update Billing Account</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="Billing_Account_Name">Billing Account Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Billing Account Name"
                name="billing_account_name"
                value={formvalues.billing_account_name}
                onChange={handlechange}
              />
              <span className="text-danger"> {formErrors.billing_account_name}</span>
            </div>
            <div className="col">
              <div className="multi-select-dropdown">
                <label htmlFor="division_id">Division Name</label>
                <OutsideAlerter setOpen={setOpen}>
                  <div className="multi-select-dropdown_wrapper">
                    <div className='multi-select-dropdown-input' onClick={() => setOpen((prev) => !prev)}>
                      {divisions.length ? `${divisions.length} Division${divisions.length > 1 ? 's' : ''} selected` : "Division Name"}
                    </div>
                    <div className='multi-select-dropdown-direction'>
                      <img src={closeCircle} height={18} onClick={closeDropDown} alt='closeIcon' />
                      {open ? <img src={downarrow} height={18} onClick={() => setOpen(false)} alt='downIcon' /> : <img src={uparrow} height={18} onClick={() => setOpen(true)} alt='upIcon' />}
                    </div>
                  </div>
                  <div className="multi-select-dropdown_options_wrapper" style={{ display: open ? "block" : "none" }}>
                    <ul className="multi-select-dropdown_options">
                      {options.map(option => {
                        return (
                          <li className="multi-select-dropdown_option" key={option.value}
                            onClick={() => handleCheckboxChange(option.value)}>
                            <input type="checkbox"
                              value={option.value}
                              checked={divisions.find((val) => val.value === option.value)}
                              onChange={() => { }}
                              className="c-multi-select-dropdown_option-checkbox"></input>
                            <span>{option.label}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </OutsideAlerter>
                <span className="text-danger" style={{ fontSize: "14px", fontWeight: "400" }}>{formErrors.division_id} </span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button type="button" onClick={handleSubmit} className="add-btn btn btn-primary  m-3">
              Update billing Account
            </button>
            <button onClick={handleBack} className="add-btn btn btn-primary  m-3">
              Back
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  )
}
export default AddSubBilling
