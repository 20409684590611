import React from 'react'
import BillingAccount from './BillingAccount'


const MemoizedBillingAccount = React.memo(BillingAccount);


const index = () => {
    return (
        <React.Fragment>
            <MemoizedBillingAccount />
        </React.Fragment>
    )
}

export default index