import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import projectReducer from './projectReducer'
import fileReducer from './fileReducer'
import experimentReducer from './expirmentReducer'
import { interalAdminReducer } from './internalAdminReducer'
import { userAdminReducer } from './userAdminReducer'

export default combineReducers({
  form: formReducer,
  projects: projectReducer,
  files: fileReducer,
  experiment: experimentReducer,
  admin: interalAdminReducer,
  userAdmin: userAdminReducer,
})