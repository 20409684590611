import React, { Component } from 'react';

import logo from '../../assets/loader.svg'
import './Loader.css'
class Loader extends Component {
  render() {
    return (
      <div className='align-items-center d-flex h-100 justify-content-center p-4'>
        <img id='loader' src={logo} style={{
          height: '100px',
          width: '100px'
        }} alt="Logo" />
      </div>
    )
  }
}

export default Loader